@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    @apply bg-marginalBlack font-semibold text-marginalGray-200;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  button {
    @apply cursor-pointer outline-none focus:outline-none focus:ring-0;
  }

  img {
    @apply shadow-none;
  }
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter-VariableFont.ttf");
}

@font-face {
  font-family: "PP Fraktion Mono";
  src: url("../public/fonts/PP\ Fraktion\ Mono-Variable.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "PP Fraktion Mono";
  src: url("../public/fonts/PP\ Fraktion\ Mono-Variable.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "PP Fraktion Mono";
  src: url("../public/fonts/PP\ Fraktion\ Mono-Variable.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "PP Fraktion Mono";
  src: url("../public/fonts/PP\ Fraktion\ Mono-Variable.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "PP Fraktion Mono";
  src: url("../public/fonts/PP\ Fraktion\ Mono-Variable.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Monument";
  font-weight: 300;
  src: url("../public/fonts/PPMonumentExtended-Light.otf");
}

@font-face {
  font-family: "Monument";
  font-weight: 400;
  src: url("../public/fonts/MonumentExtended-Regular.otf");
}

@font-face {
  font-family: "Monument";
  font-weight: 800;
  src: url("../public/fonts/MonumentExtended-Ultrabold.otf");
}

@font-face {
  font-family: "Monument";
  font-weight: 900;
  src: url("../public/fonts/PPMonumentExtended-Black.otf");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PP Fraktion Mono", "monaco", "monospace";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
